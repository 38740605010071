import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { Space, Typography } from '@sravni/react-design-system';

import type { FILTERS_NAMES } from '@src/constants/filters';
import { useSeoState } from '@src/reducers/seo';
import { FullDealEntryPointBanner, FullDealEntryPointFormCalculations, FullDealOffer } from 'entities/FullDeal';
import {
  FULL_DEAL_FORM_AMOUNT_DEFAULT,
  FULL_DEAL_FORM_TERM_DEFAULT,
  TO_FULL_DEAL_BUTTON_DEFAULT_TEXT,
} from 'entities/FullDeal/config';
import { useMicrocreditDeals } from 'entities/FullDeal/lib';
import { getPromoVariant } from 'entities/Promo/lib/getPromoVariant';
import type { FullDealOfferActionsProps } from 'features/FullDeal';
import { FullDealEntryPointForm, FullDealOfferActions } from 'features/FullDeal';
import { openPersonalProfile, sendOpenWebFullDealEventV2 } from 'features/FullDeal/lib';
import { PromoDealOffersActions } from 'features/Promo';

import { useOnChangeCalculator } from '../../lib';
import type { RenderActionsProps } from '../../types';

import styles from './FullDealEntryPoint.module.scss';

interface Props {
  isSecondary?: boolean;
  heading?: string;

  isLanding?: boolean;
  highlightOffer?: boolean;
  /**
   * @todo: стоит ли записать дефолтные значения для калькулятора в редакс,
   * это стоит делать только для одного калькулятора на странице
   */
  shouldSetDefaultValuesInRedux?: boolean;

  onChange?: (key: FILTERS_NAMES, value: number) => void;
  renderActions?: (props: RenderActionsProps) => ReactNode;
}

export const FullDealEntryPoint: FC<Props> = ({
  isSecondary,
  heading,
  isLanding,
  highlightOffer = true,
  shouldSetDefaultValuesInRedux = true,
  onChange,
  renderActions,
}) => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');
  const { seo } = useSeoState();

  const {
    initialAmount,
    initialTerm,
    hasForm,
    hasOffer,
    isOfferWaiting,
    toFullDealButtonText,
    hasIssuedMicrocreditDeals,
  } = useMicrocreditDeals({
    initialAmount: FULL_DEAL_FORM_AMOUNT_DEFAULT,
    initialTerm: FULL_DEAL_FORM_TERM_DEFAULT,
  });

  const { amount, term, handleChange } = useOnChangeCalculator({
    onChange,
    initialAmount,
    initialTerm: initialTerm as number,
    shouldSetDefaultValuesInRedux,
  });
  const FullDealOfferActionsVariant = getPromoVariant<FullDealOfferActionsProps>(
    pathWithoutQuery,
    PromoDealOffersActions,
    FullDealOfferActions,
  );

  // @todo: показываем калькулятор если нет активного офера и шаг не ожидание оффера
  const isShowCalculator = !hasOffer && !isOfferWaiting;
  const hasOfferOrForm = hasOffer || hasForm || isOfferWaiting;
  const _toFullDealButtonText = toFullDealButtonText || TO_FULL_DEAL_BUTTON_DEFAULT_TEXT;

  const highlightActiveOffer = hasOfferOrForm && highlightOffer;

  return (
    <div className={cn(styles.container, { [styles.withOfferOrForm]: highlightActiveOffer })}>
      {highlightActiveOffer && (
        <Typography.Text size={12} strong className={styles.withOfferOrFormText}>
          Продолжите оформление займа
        </Typography.Text>
      )}

      <div className={cn(styles.content, { [styles.withOfferOrFormContent]: highlightActiveOffer })}>
        <FullDealEntryPointBanner isSecondary={isSecondary} heading={seo.heading || heading} hasOffer={hasOffer} />

        {isShowCalculator && (
          <>
            <FullDealEntryPointForm amount={amount} term={term} onChange={handleChange} />
            <FullDealEntryPointFormCalculations amount={amount} term={term} />
          </>
        )}

        {(hasOffer || isOfferWaiting) && <FullDealOffer amount={initialAmount} term={initialTerm as number} />}

        <Space size={8} direction="vertical">
          {!renderActions && (
            <FullDealOfferActionsVariant
              amount={amount}
              term={term}
              hasOffer={hasOffer}
              hasIssuedMicrocreditDeals={hasIssuedMicrocreditDeals}
              toFullDealButtonText={_toFullDealButtonText}
              webFullDealAnalytics={(label) => sendOpenWebFullDealEventV2(label, isLanding)}
              onMyLoansClick={openPersonalProfile}
              seoHeading={heading}
            />
          )}

          {renderActions?.({
            amount,
            term,
            hasOffer,
            toFullDealButtonText,
          })}
        </Space>
      </div>
    </div>
  );
};
