import React, { memo } from 'react';

import { Badge, Space, Typography } from '@sravni/react-design-system';
import { useTheme } from '@sravni/react-utils';

import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';

import darkThemeImage from './assets/darkThemeImage.png';
import lightThemeImage from './assets/lightThemeImage.png';
import styles from './FullDealEntryPointBanner.module.scss';
import { FullDealEntryPointBannerTexts } from './FullDealEntryPointBanner.texts';
import { useBadgeTime } from './lib';

interface Props {
  hasOffer: boolean;
  heading?: string;
  isSecondary?: boolean;
}

export const FullDealEntryPointBanner = memo(({ heading, hasOffer, isSecondary }: Props) => {
  const [theme] = useTheme();
  const time = useBadgeTime(hasOffer);

  return (
    <Space justify="space-between" className={styles.container}>
      <div>
        <Typography.Heading level={isSecondary ? 2 : 1} className={styles.title}>
          {heading || FullDealEntryPointBannerTexts.title}
        </Typography.Heading>
        <Badge
          variant="primary"
          color="gray"
          text={
            hasOffer
              ? FullDealEntryPointBannerTexts.badgeTitle
              : FullDealEntryPointBannerTexts.badgeTitleWithTime.replace('{time}', time)
          }
        />
      </div>

      <OptimizedPicture
        imgClassName={styles.image}
        img={theme === 'lager' ? lightThemeImage : darkThemeImage}
        width={177}
        height={120}
        loading="eager"
        fetchpriority="high"
        alt="logo"
      />
    </Space>
  );
});

FullDealEntryPointBanner.displayName = 'FullDealEntryPointBanner';
